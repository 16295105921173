import React, { useState, useEffect } from "react";
import { object, shape } from "prop-types";
// import throttle from "lodash/throttle";
import { LazyCollection, LazyLoadImages, replaceAllStoriesInCollection, WithPreview } from "@quintype/components";

import TaboolaScript from "../ads/taboola-ad/load-taboola-ad";
import TaboolaAd from "../ads/taboola-ad";
import MobileCollection from "../collection-templates/mobile-collection";

import { getCollectionTemplate } from "../get-collection-template";
import useDevice from "../helper/custom-hook/useDevice";

export const HomePage = ({ data }) => {
  const [renderTaboolaAd, setRenderTaboolaAd] = useState(true);
  const isMobile = useDevice();

  // useEffect(() => {
  //   const getScroll = () => {
  //     if (typeof window !== "undefined") {
  //       if (window.scrollY > 500) setRenderTaboolaAd(true);
  //     }
  //   };
  //   window.addEventListener("scroll", throttle(getScroll, 300));
  //   return () => window.removeEventListener("scroll", getScroll);
  // }, []);

  const filterCollections = data?.collection?.items?.filter((i) => i.items.length);
  const collection1 = { ...data?.collection };
  collection1.items = [filterCollections && filterCollections[0]];
  const remainingCollection = { ...data?.collection };
  remainingCollection.items = filterCollections?.slice(1);

  return (
    <div className="container">
      <TaboolaScript type="homepage" />
      <LazyLoadImages>
        <div className="hide-mobile">
          <LazyCollection
            collection={collection1}
            collectionTemplates={getCollectionTemplate}
            lazyAfter={2}
            className="lazy-collection"
          />
        </div>
        {isMobile && (
          <div className="hide-desktop">
            <MobileCollection collection={collection1.items[0]} />
          </div>
        )}
        <LazyCollection
          minHeight={0}
          collection={remainingCollection}
          collectionTemplates={getCollectionTemplate}
          lazyAfter={2}
          className="lazy-collection"
        />
      </LazyLoadImages>
      {renderTaboolaAd ? (
        <TaboolaAd container="taboola-below-home-thumbnails" placement="Below Home Thumbnails" mode="thumbnails-d" />
      ) : null}
    </div>
  );
};

HomePage.propTypes = {
  data: shape({
    collection: object
  })
};

export const HomePagePreview = WithPreview(HomePage, (data, story) =>
  Object.assign({}, data, {
    collection: replaceAllStoriesInCollection(data.collection, story)
  })
);
